import React, { useState, } from "react";
import "./Login.css"; // Import your CSS file here




const currentURL = window.location.href;
console.log(currentURL);

const Login = () => {

 
  let name = "Admin";
  let pass = "Admin@93";
  let ArrayLog = [{name : "Admin",pass : "Admin@93"},{name : "Ashutosh", pass : "Ashutosh_45"}]

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };


  // const handleLogin = async (normalUsername, normalPassword) => {

  //   try {
  //     if (normalUsername == name && normalPassword == pass) {

  //       console.log("Login =--0-0-0-0-0-0--0-0--0-");
  //       const currentURL = window.location.href;
  //       console.log(currentURL);
  //       console.log(currentURL);
  //       console.log(currentURL);
  //       window.location.href = `${currentURL}4e7afebcfbae000b22c7c85e5560f89a2a0280b4`;
  //     }else{
  //       alert(" Invalid username and password ")
  //       setUsername("");
  //       setPassword("");
  //     }
  //   } catch (error) {
    
  //     console.error("Error:", error);
  //   }
  // };

  const handleLogin = async (normalUsername, normalPassword) => {
    try {
        let isLoggedIn = false;
        for (const user of ArrayLog) {
            if (user.name === normalUsername && user.pass === normalPassword) {
                isLoggedIn = true;
                break;
            }
        }

        if (isLoggedIn) {
            console.log("Login successful");
            const currentURL = window.location.href;
            window.location.href = `${currentURL}4e7afebcfbae000b22c7c85e5560f89a2a0280b4`;
        } else {
            alert("Invalid username and password");
            setUsername("");
            setPassword("");
        }
    } catch (error) {
        console.error("Error:", error);
    }
};




  const handleSubmit = (event) => {
    event.preventDefault();
    handleLogin(username, password);
  };

  return (<>
    <div className='firstComponent'>

      <div className='parent-login'>
        <div className='child-first-login'>
          <h2 className='heading-section-login'> Portfolio Performance</h2>
          <h2 className='heading-section-login'> Viewer</h2>
        </div>
        <div className='child-second-login'>
          <div className='nested-child-login-first'>
            <h3>Welcome</h3>
            <p>Sign in by entering the information below</p>
          </div>

          <form onSubmit={handleSubmit} className='nested-child-login-second login'>
            <div className="form-group">
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="fa fa-user"></span>
              </div>
              <input
                id="onlyone"
                type="text"
                value={username}
                autoComplete="username"
                onChange={handleUsernameChange}
                className="form-control"
                placeholder="Username"
              />
            </div>
            <div className="form-group">
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="fa fa-lock"></span>
              </div>
              <input
                id="onlyone-1"
                type="password"
                value={password}
                autoComplete="current-password"
                onChange={handlePasswordChange}
                className="form-control"
                placeholder="Password"
                required
              />
            </div>
            <div className='submit-btn'>
              <button type="submit" className="btn" >
                Login
              </button>
            </div>
          </form>

        </div>

      </div>
    </div>
  </>
  );
};

export default Login;
