
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Components/Login';
//import Setups from './Setups';



function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route element={<Login/>} path='/' />
      </Routes>
    </BrowserRouter>
 
  );
}

export default App;

// function App() {
//   return (
//     <PrimeReactProvider>
//     <BrowserRouter>
//       <Routes>
//         <Route element={<Setups/>} path='/' />
//       </Routes>
//     </BrowserRouter>
//     </PrimeReactProvider>
//   );
// }

// export default App;